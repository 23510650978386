import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { TranslationService } from '@mitchell/redux.module.apd/dist/domain/common/services/TranslationService';
import { GPCRootComponent } from './components';
import { Store } from './store';

const App = (): React.ReactElement => {
	
	const urlParams = new URLSearchParams(window.location.search);
	const coCode = urlParams.get('coCd') || localStorage.getItem('coCd') || '';
	const contextId = urlParams.get('id') || localStorage.getItem('id') || '';
	const language = urlParams.get('lang') || localStorage.getItem('lang') || '';
    // set coCd,id and lang in localStorage  
	localStorage.setItem('coCd', coCode || '');
	localStorage.setItem('id', contextId || '');
	localStorage.setItem('lang', language || '');
	TranslationService.setCurrentLanguage(language);

	return (
		<Provider store={Store}>
			<Router basename={'/'}>
				<GPCRootComponent companyCode={coCode} contextId={contextId} language={language} />
			</Router>
		</Provider>
	);
};

export default App;
