import {
	PhotoCaptureAttributeType,
	PhotoCaptureAssignmentTypes,
	PhotoCaptureAssignmentSubTypes,
} from '@mitchell/redux.module.apd/dist/domain/photoCapture';
import { PhotoCaptureAnswerControlType } from '../enums';
import { RouteConstant } from '../constants';

export const redirectToRoute = (
	history: any,
	answerControlType: string | null = '',
	questionAttributeType: string | null = '',
	assignmentType: PhotoCaptureAssignmentTypes = PhotoCaptureAssignmentTypes.GENRIC_PHOTO_CAPTURE,
	assignmentSubType: string | null = '',
	qs: string = '',
	isMultiSelectionAllowed: boolean = false
): void => {
	switch (answerControlType) {
		case PhotoCaptureAnswerControlType.SCRIPT_QUESTION:
			redirectToScriptQuestionByAttributeType(history, questionAttributeType);
			break;
		case PhotoCaptureAnswerControlType.ADD_ATTACHMENT:
			redirectToRouteByAttributeType(history, questionAttributeType, assignmentType, assignmentSubType);
			break;
		case PhotoCaptureAnswerControlType.YES_NO_VALUE:
			history.push(`/${RouteConstant.PBE_YES_NO}`);
			break;
		case PhotoCaptureAnswerControlType.CHOICE_SELECTION:
			redirectToRouteForChoiceType(history, isMultiSelectionAllowed);

			break;
		default:
			history.push(`/${RouteConstant.PBE_GPC_MESSAGE}?${qs}`);
	}
};

const redirectToRouteForChoiceType = (history: any, isMultiSelectionAllowed: boolean): void => {
	if (isMultiSelectionAllowed) history.push(`/${RouteConstant.PBE_MULTI_CHOICE_SELECTION}`);
	else history.push(`/${RouteConstant.PBE_SINGLE_CHOICE_SELECTION}`);
};

export const redirectToRouteByAttributeType = (
	history: any,
	questionAttributeType: string | null,
	assignmentType: PhotoCaptureAssignmentTypes,
	assignmentSubType: string | null
): void => {
	switch (questionAttributeType) {
		case PhotoCaptureAttributeType.VIN_CAPTURE:
			history.push(`/${RouteConstant.PBE_IDENTIFICATION}`);
			break;
		case PhotoCaptureAttributeType.MILEAGE_CAPTURE:
			history.push(`/${RouteConstant.PBE_ODOMETER}`);
			break;
		case PhotoCaptureAttributeType.LICENSE_PLATE_CAPTURE:
			history.push(`/${RouteConstant.PBE_LICENSE_PLATE}`);
			break;
		case PhotoCaptureAttributeType.SINGLE_WIDE_SHOT:
			history.push(`/${RouteConstant.PBE_WIDE_SHOT}`);
			break;
		case PhotoCaptureAttributeType.THREE_CLOSE_UP:
			history.push(`/${RouteConstant.PBE_CLOSEUP_SHOT}`);
			break;
		case PhotoCaptureAttributeType.FOUR_CORNERS_CAPTURE:
			history.push(`/${RouteConstant.PBE_FOUR_CORNERS}`);
			break;
		case PhotoCaptureAttributeType.VIDEO_CAPTURE:
			history.push(`/${RouteConstant.PBE_WALK_AROUND_VIDEO}`);
			break;
		case PhotoCaptureAttributeType.VIN_DECODE:
			history.push(`/${RouteConstant.PBE_VIN_DECODE}`);
			break;
		case PhotoCaptureAttributeType.DEFAULT:
			redirectByAssignmentTypeAndSubType(history, assignmentType, assignmentSubType);
			break;
	}
};
export const redirectToScriptQuestionByAttributeType = (history: any, questionAttributeType: string | null): void => {
	switch (questionAttributeType) {
		case PhotoCaptureAttributeType.DEFAULT:
			history.push(`/${RouteConstant.PBE_CONFIGURE_HOME}`);
			break;
		case PhotoCaptureAttributeType.ACKNOWLEDGEMENT:
			history.push(`/${RouteConstant.PBE_TERMS_AND_CONDITIONS}`);
			break;
		case PhotoCaptureAttributeType.TERMINATE:
			history.push(`/${RouteConstant.PBE_TERMINATE}`);
			break;
	}
};

export const redirectByAssignmentTypeAndSubType = (
	history: any,
	assignmentType: PhotoCaptureAssignmentTypes,
	assignmentSubType: string | null
): void => {
	switch (assignmentType) {
		case PhotoCaptureAssignmentTypes.PHOTO_CAPTURE:
			history.push(`/${RouteConstant.PBE_REQUEST_OPTIONAL}`);
			break;
		case PhotoCaptureAssignmentTypes.REQ_ADDITIONAL_PHOTO:
			history.push(`/${RouteConstant.PBE_REQUEST_ADDITIONAL}`);
			break;
		case PhotoCaptureAssignmentTypes.GENRIC_PHOTO_CAPTURE:
			assignmentSubType === PhotoCaptureAssignmentSubTypes.RP
				? history.push(`/${RouteConstant.PBE_REQUEST_ADDITIONAL}`)
				: history.push(`/${RouteConstant.PBE_REQUEST_OPTIONAL}`);
	}
};
