import {
	PhotoCaptureAllowedMimeTypeConstant,
	PhotoCaptureAttributeType,
	PhotoCaptureVideoMimeTypesConstant,
	PhotoCaptureFileExtensionAllowedForGallery,
} from '@mitchell/redux.module.apd/dist/domain/photoCapture';
import { TranslationService } from '@mitchell/redux.module.apd/dist/domain/common/services/TranslationService';
import { ConvertBytesToMegaBytes } from '@mitchell/redux.module.apd/dist/domain/common/services/ConvertToByteUtils';

import { FileUploadErrorMessage } from '../constants';
import { PhotoCaptureAnswerControlType } from '../enums';
import { FileUploadError } from '@mitchell/redux.module.apd/dist/domain/file/enums/FileUploadError';

export const getWarningMessageByErrorType = (
	uploadError: FileUploadError,
	maxImageFileSize: number,
	maxVideoFileSize: number | undefined,
	answerControlType: string | null = '',
	questionAttributeType: string | null = ''
): string => {
	let message: string = '';
	switch (uploadError) {
		case FileUploadError.INVALID_FILE_TYPE:
			message = maxVideoFileSize
				? `${TranslationService.translate(
						FileUploadErrorMessage.INVALID_FILE_TYPE
				  )} *.${PhotoCaptureVideoMimeTypesConstant.join(', *.').toLowerCase()}.`
				: getMessageForInvalidFileType(answerControlType, questionAttributeType);
			break;
		case FileUploadError.MAX_FILE_SIZE_EXCEEDED:
			message = `${TranslationService.translate(
				FileUploadErrorMessage.MAX_FILE_SIZE_EXCEEDED
			)} ${ConvertBytesToMegaBytes(maxImageFileSize)} MB.`;
			break;
		case FileUploadError.MAX_VIDEO_FILE_SIZE_EXCEEDED:
			message = maxVideoFileSize
				? `${TranslationService.translate(FileUploadErrorMessage.MAX_FILE_SIZE_EXCEEDED)} ${ConvertBytesToMegaBytes(
						maxVideoFileSize
				  )} MB.`
				: '';
			break;
	}
	return message;
};

export const getMessageForInvalidFileType = (
	answerControlType: string | null = '',
	questionAttributeType: string | null = ''
): string => {
	return answerControlType === PhotoCaptureAnswerControlType.ADD_ATTACHMENT &&
		questionAttributeType === PhotoCaptureAttributeType.DEFAULT
		? `${TranslationService.translate(
				FileUploadErrorMessage.INVALID_FILE_TYPE
		  )} *.${PhotoCaptureFileExtensionAllowedForGallery.join(', *.').toLowerCase()}.`
		: `${TranslationService.translate(
				FileUploadErrorMessage.INVALID_FILE_TYPE
		  )} *.${PhotoCaptureAllowedMimeTypeConstant.join(', *.').toLowerCase()}.`;
};
