import { ConnectedSinglePhotoUpload } from '@mitchell/redux.module.apd/dist/app/guidedPhotoCapture';
import { IShellConfig } from '../interfaces';

export const shellConfig: IShellConfig = {
	baseName: '',
	navItems: [
		{
			path: '/test',
			component: ConnectedSinglePhotoUpload,
		},
	],
};
