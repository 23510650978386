import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { gpcRootReducer, IGPCGlobalState } from '@mitchell/redux.module.apd/dist/app/guidedPhotoCapture';
import {
	IPhotoCaptureGlobalState,
	photoCaptureRootReducer,
	photoCaptureRootSaga,
} from '@mitchell/redux.module.apd/dist/domain/photoCapture';
import {
	IQuestionnaireEvaluationGlobalState,
	questionnaireEvaluationRootReducer,
	questionnaireEvaluationRootSaga,
} from '@mitchell/redux.module.apd/dist/domain/questionnaireEvaluation';
import {
	attachmentRootReducer,
	attachmentRootSaga,
	IAttachmentGlobalState,
} from '@mitchell/redux.module.apd/dist/domain/attachment';
import { fileRootReducer, fileRootSaga, IFileGlobalState } from '@mitchell/redux.module.apd/dist/domain/file';
import { BaseAction } from '@mitchell/redux.module.apd/dist/domain/common/interfaces/BaseAction';
import { brandedNotificationRootSaga } from '@mitchell/redux.module.apd/dist/domain/brandedNotification';
import {
	vehicleInfoRootSaga,
	VehicleInfoGlobalState,
	VehicleSelectionMethod,
	VehicleInfoEntry,
	updateVehicleInfo,
	updateSelectedInfoEntries,
} from '@mitchell/redux.module.apd/dist/domain/vehicleInfo';
import { RESET_STORE } from '../constants';

type GPCShellGlobalState = IGPCGlobalState &
	IPhotoCaptureGlobalState &
	IQuestionnaireEvaluationGlobalState &
	IAttachmentGlobalState &
	IFileGlobalState &
	VehicleInfoGlobalState;

const storeData: GPCShellGlobalState = require('./storeData.json');

export const getEnvironment = (): string => {
	const baseUrl = window.location.hostname.toLowerCase();
	let environment = '';
	if (baseUrl.includes('-dev.mymitchell.com') || !baseUrl.includes('.mymitchell.com')) environment = 'DEV';
	else if (baseUrl.includes('-qa.mymitchell.com')) environment = 'QA';
	else if (baseUrl.includes('ca-uat.mymitchell.com')) environment = 'UATCA';
	else if (baseUrl.includes('-uat.mymitchell.com')) environment = 'UAT';
	else if (baseUrl.includes('ca.mymitchell.com')) environment = 'PRODCA';
	else environment = 'PROD';

	return environment;
};

const sagaMiddleware = createSagaMiddleware();

const initialGlobalState: GPCShellGlobalState = {
	...storeData,
	domain: {
		questionnaireEvaluation: storeData.domain.questionnaireEvaluation,
		photoCapture: storeData.domain.photoCapture,
		attachment: storeData.domain.attachment,
		file: storeData.domain.file,
		vehicleInfo: {
			selectedInfoEntries: new Map<string, VehicleInfoEntry<string | number>>(),
			vehicleInfoData: {
				VehicleSelectionMethod: VehicleSelectionMethod.VinDecode,
				Vehicle: null,
			},
		},
	},
};

type Reducer<State, Action> = (prevState: State, action: Action) => State;

const appReducer: Reducer<any, BaseAction<any>> = combineReducers({
	app: combineReducers({
		guidedPhotoCapture: gpcRootReducer,
	}),
	domain: combineReducers({
		file: fileRootReducer,
		attachment: attachmentRootReducer,
		photoCapture: photoCaptureRootReducer,
		questionnaireEvaluation: questionnaireEvaluationRootReducer,
		vehicleInfo: combineReducers({
			vehicleInfoData: updateVehicleInfo,
			selectedInfoEntries: updateSelectedInfoEntries,
		}),
	}),
});

const rootReducer: Reducer<any, BaseAction<any>> = (state: GPCShellGlobalState, action: BaseAction<any>) => {
	if (action.type === RESET_STORE) {
		state = initialGlobalState;
	}
	const appState: GPCShellGlobalState = appReducer(state, action);
	appState.app = { ...state.app, ...appState.app };
	return appState;
};

window.mitchell = { environment: getEnvironment() };

function* rootSaga() {
	yield all([
		brandedNotificationRootSaga(),
		photoCaptureRootSaga(),
		questionnaireEvaluationRootSaga(),
		...attachmentRootSaga,
		...fileRootSaga,
		...vehicleInfoRootSaga,
	]);
}

export const Store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: false,
			immutableCheck: false,
			serializableCheck: false,
		}).prepend(sagaMiddleware),
	devTools: process.env.NODE_ENV !== 'production',
	preloadedState: initialGlobalState,
});

sagaMiddleware.run(rootSaga);
