import React from 'react';
import { Router, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
	getQuestionnaireEvaluationQuestionnaireQuestionId,
	getQuestionnaireEvaluationAnswerControlType,
	getQuestionnaireEvaluationQuestionAttributeType,
	getQuestionnaireEvaluationQuestionList,
	getQuestionnaireEvaluationDocId,
	IQuestionnaireEvaluationGlobalState,
	IQuestionnaireEvaluationQuestionData,
	questionnaireEvaluationQuestionGetSuccessAction,
	getIsQuestionnaireEvaluationMultiSelectAllowed,
} from '@mitchell/redux.module.apd/dist/domain/questionnaireEvaluation';
import {
	IFileGlobalState,
	getFileInfoState,
	IFileInfo,
	FileUploadStatus,
	getFileUploadMaxSizeState,
	IFileInfoState,
	isFileUploadModalOpen,
} from '@mitchell/redux.module.apd/dist/domain/file';
import {
	getGPCLoadingStatus,
	getGPCTheme,
	ConnectedGPCLoader,
	ConnectedPhotoCaptureMessage,
	ConnectedScriptQuestion,
	ConnectedSinglePhotoUpload,
	ConnectedSingleWideShot,
	ConnectedCloseUpComponent,
	ConnectedFourCornersComponent,
	ConnectedAdditionalPhotosComponent,
	ConnectedWalkAroundVideoComponent,
	ConnectedYesNoComponent,
	ConnectedChoiceSelectionComponent,
	IGPCGlobalState,
	ConnectedVinDecodeComponent,
} from '@mitchell/redux.module.apd/dist/app/guidedPhotoCapture';
import {
	getPhotoCaptureAssignmentType,
	PhotoCaptureAssignmentTypes,
	getPhotoCaptureAssignmentSubType,
	IPhotoCaptureScriptQuestionTheme,
	IPhotoCaptureUpdateContextRequest,
	IPhotoCaptureAttachmentFileInfo,
	photoCapturePhotoUploadAction,
	PhotoCaptureMessageType,
	photoCaptureUpdateContextDataAction,
	getPhotoCaptureWorkItemStatus,
	getPhotoCaptureAttachmentInfo,
	getPhotoCaptureIsTermsAndConditionAccepted,
	getPhotoCaptureEvaluationDocId,
	hasPhotoCaptureContextUpdated,
	PhotoCaptureConstants,
	IPhotoCaptureGlobalState,
	getSourceNameByAssignmentTypeAndSubType,
	PhotoCaptureAssignmentSubTypes,
	getPhotoCaptureFileSizeLimit,
	PhotoCaptureAttributeType,
} from '@mitchell/redux.module.apd/dist/domain/photoCapture';
import { IAttachmentGlobalState } from '@mitchell/redux.module.apd/dist/domain/attachment';
import { TranslationService } from '@mitchell/redux.module.apd/dist/domain/common/services/TranslationService';

import { IGPCRootComponent } from '../interfaces';
import { redirectToRoute, getWarningMessageByErrorType } from '../utilities';
import { RouteConstant, GPCLanguages } from '../constants';
import { StyledDivShellContainer, StyledDivContent } from '../styles';
import { GPCLoaderComponent } from './GPCLoaderComponent';
import { PhotoCaptureAnswerControlType } from '../enums/PhotoCaptureAnswerControlType';

type GPCShellGlobalState = IGPCGlobalState &
	IPhotoCaptureGlobalState &
	IQuestionnaireEvaluationGlobalState &
	IAttachmentGlobalState &
	IFileGlobalState;
export const GPCRootComponent = ({ companyCode, contextId, language }: IGPCRootComponent): React.ReactElement => {
	const [messageType, setMessageType] = React.useState<string>('');
	const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
	const [warningMessage, setWarningMessage] = React.useState<string>('');
	const [failedFileId, setFailedFileId] = React.useState<string>('');
	const history = useHistory();
	const dispatch = useDispatch();
	const questionnaireQuestionId: number | undefined = useSelector((state: GPCShellGlobalState) =>
		getQuestionnaireEvaluationQuestionnaireQuestionId(state)
	);
	const answerControlType: string | null = useSelector((state: GPCShellGlobalState) =>
		getQuestionnaireEvaluationAnswerControlType(state)
	);
	const questionAttributeType: string | null = useSelector((state: GPCShellGlobalState) =>
		getQuestionnaireEvaluationQuestionAttributeType(state)
	);
	const questionList: Array<string> | Array<IQuestionnaireEvaluationQuestionData> = useSelector(
		(state: GPCShellGlobalState) => getQuestionnaireEvaluationQuestionList(state)
	);
	const workItemStatus: string | null = useSelector((state: GPCShellGlobalState) =>
		getPhotoCaptureWorkItemStatus(state)
	);
	const isLoading: boolean = useSelector((state: GPCShellGlobalState) => getGPCLoadingStatus(state));
	const photoCaptureAttachmentInfo: Array<Array<IPhotoCaptureAttachmentFileInfo>> | Array<[]> = useSelector(
		(state: GPCShellGlobalState) => getPhotoCaptureAttachmentInfo(state)
	);
	const isTermsAndConditionAccepted: boolean = useSelector((state: GPCShellGlobalState) =>
		getPhotoCaptureIsTermsAndConditionAccepted(state)
	);
	const evaluationDocId: string | null = useSelector(
		(state: GPCShellGlobalState) =>
			getPhotoCaptureEvaluationDocId(state) || getQuestionnaireEvaluationDocId(state).toString()
	);
	const isContextUpdated: boolean = useSelector((state: GPCShellGlobalState) => hasPhotoCaptureContextUpdated(state));
	const { applicationBackgroundColor }: IPhotoCaptureScriptQuestionTheme = useSelector((state: GPCShellGlobalState) =>
		getGPCTheme(state)
	);
	const assignmentType: PhotoCaptureAssignmentTypes = useSelector((state: GPCShellGlobalState) =>
		getPhotoCaptureAssignmentType(state)
	);
	const isMultiSelectionAllowed: boolean = useSelector((state: GPCShellGlobalState) =>
		getIsQuestionnaireEvaluationMultiSelectAllowed(state)
	);
	const assignmentSubType: PhotoCaptureAssignmentSubTypes | null = useSelector((state: GPCShellGlobalState) =>
		getPhotoCaptureAssignmentSubType(state)
	);
	const { byId, allIds }: IFileInfoState = useSelector((state: GPCShellGlobalState) => getFileInfoState(state));

	const isRAPFileUploadModalOpen: boolean = useSelector((state: GPCShellGlobalState) => isFileUploadModalOpen(state));

	const sourceName: string = getSourceNameByAssignmentTypeAndSubType(assignmentType, assignmentSubType);
	const maxVideoFileSize: number | undefined = useSelector(
		(state: GPCShellGlobalState) => getFileUploadMaxSizeState(state)?.maxVideoFileSize
	);

	const maxImageFileSize: number = useSelector((state: GPCShellGlobalState) => getPhotoCaptureFileSizeLimit(state));

	const setAppLanguage = (appLanguage: string): void => {
		localStorage.setItem('lang', appLanguage);
		TranslationService.setCurrentLanguage(appLanguage);
	};

	React.useEffect(() => {
		if (allIds.length) {
			setFailedFileId(allIds[0]);
			const { uploadStatus, uploadError, fileName }: IFileInfo = byId[allIds[0]];
			if (failedFileId !== allIds[0] && uploadStatus === FileUploadStatus.FAILED && uploadError) {
				if (
					answerControlType === PhotoCaptureAnswerControlType.ADD_ATTACHMENT &&
					questionAttributeType !== PhotoCaptureAttributeType.DEFAULT
				) {
					const message: string = getWarningMessageByErrorType(
						uploadError,
						maxImageFileSize,
						maxVideoFileSize,
						answerControlType,
						questionAttributeType
					);
					setWarningMessage(`${fileName} ${message}`);
				}
			}
		}
	}, [allIds, answerControlType, byId, failedFileId, maxImageFileSize, maxVideoFileSize, questionAttributeType]);

	React.useEffect(() => {
		if (isLoading) {
			setWarningMessage(``);
		}
	}, [isLoading]);

	React.useEffect(() => {
		if (questionList.length && !questionList[0]) {
			dispatch(photoCapturePhotoUploadAction(contextId, companyCode, sourceName));
		}
	}, [companyCode, contextId, dispatch, questionList, sourceName]);
	React.useEffect(() => {
		if (workItemStatus === PhotoCaptureMessageType.SUCCESS && isContextUpdated) {
			dispatch(questionnaireEvaluationQuestionGetSuccessAction({} as IQuestionnaireEvaluationQuestionData));
		}
	}, [workItemStatus, isContextUpdated, dispatch]);
	React.useEffect(() => {
		if (workItemStatus === PhotoCaptureMessageType.SUCCESS && isContextUpdated) {
			// and we have to call get profile service before redirecting to Success page.
			redirectToRoute(history);
		} else if (workItemStatus === PhotoCaptureMessageType.SUCCESS) {
			setMessageType(PhotoCaptureMessageType.SUCCESS);
			const contextDataUpdateInput: IPhotoCaptureUpdateContextRequest = {
				attachmentInfo: photoCaptureAttachmentInfo,
				documentId: evaluationDocId,
				fraudWarningAccept: '0',
				isTermsAndConditionAccepted: isTermsAndConditionAccepted ? PhotoCaptureConstants.Y : PhotoCaptureConstants.N,
				status: null,
				vehicle: null,
			};
			dispatch(photoCaptureUpdateContextDataAction(contextDataUpdateInput, contextId, companyCode));
		}
	}, [
		workItemStatus,
		isContextUpdated,
		history,
		photoCaptureAttachmentInfo,
		evaluationDocId,
		isTermsAndConditionAccepted,
		dispatch,
		contextId,
		companyCode,
	]);
	React.useEffect(() => {
		if (answerControlType && questionAttributeType)
			redirectToRoute(
				history,
				answerControlType,
				questionAttributeType,
				assignmentType,
				assignmentSubType,
				'',
				isMultiSelectionAllowed
			);
	}, [
		questionnaireQuestionId,
		answerControlType,
		history,
		questionAttributeType,
		assignmentType,
		assignmentSubType,
		isMultiSelectionAllowed,
	]);
	const onGPCSuccess = (): void => {
		setIsInitialized(true);
		redirectToRoute(
			history,
			answerControlType,
			questionAttributeType,
			assignmentType,
			assignmentSubType,
			'',
			isMultiSelectionAllowed
		);
	};
	const onGPCError = (value: string): void => {
		setMessageType(value);
		setIsInitialized(true);
		redirectToRoute(history, '', '', PhotoCaptureAssignmentTypes.GENRIC_PHOTO_CAPTURE, '', `type=${value}`);
	};
	return (
		<StyledDivShellContainer backgroundColor={applicationBackgroundColor}>
			<StyledDivContent>
				<Router history={history}>
					<div>
						<Route path={'/'}>
							<ConnectedGPCLoader
								coCode={companyCode}
								contextId={contextId}
								language={TranslationService.getCurrentLanguage()}
								onGPCError={onGPCError}
								onGPCSuccess={onGPCSuccess}
							/>
						</Route>
						{isInitialized && (
							<>
								<GPCLoaderComponent
									isLoading={isLoading}
									questionAttributeType={questionAttributeType}
									uploadStatus={byId[allIds[0]]?.uploadStatus}
									isRAPFileUploadModalOpen={isRAPFileUploadModalOpen}
								/>
								<Route exact path={`/${RouteConstant.PBE_CONFIGURE_HOME}`}>
									<ConnectedScriptQuestion
										languages={GPCLanguages}
										language={TranslationService.getCurrentLanguage()}
										warningMessage={warningMessage}
										onLanguageChange={setAppLanguage}
									/>
								</Route>
								<Route exact path={`/${RouteConstant.PBE_TERMS_AND_CONDITIONS}`}>
									<ConnectedScriptQuestion warningMessage={warningMessage} />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_TERMINATE}`}>
									<ConnectedScriptQuestion warningMessage={warningMessage} />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_IDENTIFICATION}`}>
									<ConnectedSinglePhotoUpload warningMessage={warningMessage} />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_ODOMETER}`}>
									<ConnectedSinglePhotoUpload warningMessage={warningMessage} />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_LICENSE_PLATE}`}>
									<ConnectedSinglePhotoUpload warningMessage={warningMessage} />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_VIN_DECODE}`}>
									<ConnectedVinDecodeComponent warningMessage={warningMessage} />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_WIDE_SHOT}`}>
									<ConnectedSingleWideShot warningMessage={warningMessage} />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_CLOSEUP_SHOT}`}>
									<ConnectedCloseUpComponent warningMessage={warningMessage} />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_FOUR_CORNERS}`}>
									<ConnectedFourCornersComponent warningMessage={warningMessage} />
								</Route>
								<Route path={`/${RouteConstant.PBE_REQUEST_ADDITIONAL}`}>
									<ConnectedAdditionalPhotosComponent
										language={TranslationService.getCurrentLanguage()}
										warningMessage={warningMessage}
									/>
								</Route>
								<Route path={`/${RouteConstant.PBE_REQUEST_OPTIONAL}`}>
									<ConnectedAdditionalPhotosComponent
										language={TranslationService.getCurrentLanguage()}
										warningMessage={warningMessage}
									/>
								</Route>
								<Route path={`/${RouteConstant.PBE_WALK_AROUND_VIDEO}`}>
									<ConnectedWalkAroundVideoComponent warningMessage={warningMessage} />
								</Route>
								<Route path={`/${RouteConstant.PBE_YES_NO}`}>
									<ConnectedYesNoComponent />
								</Route>
								<Route path={`/${RouteConstant.PBE_SINGLE_CHOICE_SELECTION}`}>
									<ConnectedChoiceSelectionComponent />
								</Route>
								<Route path={`/${RouteConstant.PBE_MULTI_CHOICE_SELECTION}`}>
									<ConnectedChoiceSelectionComponent />
								</Route>
								<Route exact path={`/${RouteConstant.PBE_GPC_MESSAGE}`}>
									<ConnectedPhotoCaptureMessage messageType={messageType} />
								</Route>
							</>
						)}
					</div>
				</Router>
			</StyledDivContent>
		</StyledDivShellContainer>
	);
};
