export const RESET_STORE = 'RESET_STORE';

export const GPCLanguages = [
	{
		value: 'en-US',
		label: 'English (US)',
	},
	{
		value: 'en-GB',
		label: 'English (UK)',
	},
	{
		value: 'de-DE',
		label: 'Deutsch (Deutschland)',
	},
	{
		value: 'es-ES',
		label: 'Español (España)',
	},
	{
		value: 'fr-CA',
		label: 'Français (Canada)',
	},
	{
		value: 'fr-FR',
		label: 'Français (France)',
	},
	{
		value: 'it-IT',
		label: 'Italiano (Italia)',
	},
];

export const FileUploadErrorMessage = {
	INVALID_FILE_TYPE: 'has an invalid extension. Valid extension(s):',
	MAX_FILE_SIZE_EXCEEDED: 'is too large, maximum file size is',
};
