import numbro from 'numbro';

numbro.registerLanguage({
	languageTag: 'en-US',

	delimiters: {
		thousands: ',',
		decimal: '.',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	ordinal: (number) => {
		return number === 1 ? 'er' : 'ème';
	},
	currency: {
		symbol: '$',
		position: 'prefix',
		code: 'USD',
	},
	currencyFormat: {
		thousandSeparated: true,
		mantissa: 2,
	},
	formats: {
		fourDigits: {
			totalLength: 4,
		},
		fullWithTwoDecimals: {
			output: 'currency',
			mantissa: 2,
			thousandSeparated: true,
		},
		fullWithTwoDecimalsNoCurrency: {
			mantissa: 2,
			thousandSeparated: true,
		},
		fullWithNoDecimals: {
			output: 'currency',
			thousandSeparated: true,
			mantissa: 0,
		},
	},
});

numbro.registerLanguage({
	languageTag: 'en-CA',

	delimiters: {
		thousands: ',',
		decimal: '.',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	ordinal: (number) => {
		return number === 1 ? 'er' : 'ème';
	},
	currency: {
		symbol: '$',
		position: 'prefix',
		code: 'USD',
	},
	currencyFormat: {
		thousandSeparated: true,
		mantissa: 2,
	},
	formats: {
		fourDigits: {
			totalLength: 4,
		},
		fullWithTwoDecimals: {
			output: 'currency',
			mantissa: 2,
			thousandSeparated: true,
		},
		fullWithTwoDecimalsNoCurrency: {
			mantissa: 2,
			thousandSeparated: true,
		},
		fullWithNoDecimals: {
			output: 'currency',
			thousandSeparated: true,
			mantissa: 0,
		},
	},
});

numbro.registerLanguage({
	languageTag: 'fr-CA',
	delimiters: {
		thousands: ' ',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	ordinal: (number) => {
		return number === 1 ? 'er' : 'ème';
	},
	currency: {
		symbol: '$',
		position: 'postfix',
		code: 'CAD',
	},
	currencyFormat: {
		thousandSeparated: true,
		spaceSeparated: true,
		mantissa: 2,
	},
	formats: {
		fourDigits: {
			totalLength: 4,
			spaceSeparated: true,
		},
		fullWithTwoDecimals: {
			output: 'currency',
			mantissa: 2,
			thousandSeparated: true,
			spaceSeparated: true,
		},
		fullWithTwoDecimalsNoCurrency: {
			mantissa: 2,
			thousandSeparated: true,
			spaceSeparated: true,
		},
		fullWithNoDecimals: {
			output: 'currency',
			thousandSeparated: true,
			mantissa: 0,
			spaceSeparated: true,
		},
	},
});
