import React from 'react';
import { ProgressCircle } from '@mitchell/react.component.ux';
import { PhotoCaptureAttributeType } from '@mitchell/redux.module.apd/dist/domain/photoCapture';
import { FileUploadStatus } from '@mitchell/redux.module.apd/dist/domain/file';
import { StyledOverlayDiv, StyledSpinnerDiv } from './GPCLoaderComponent.style';

interface IGPCLoaderProps {
	isLoading: boolean;
	isRAPFileUploadModalOpen: boolean;
	questionAttributeType: string | null;
	uploadStatus: FileUploadStatus;
}

export const GPCLoaderComponent: React.FC<IGPCLoaderProps> = ({
	isLoading,
	questionAttributeType,
	uploadStatus,
	isRAPFileUploadModalOpen,
}: IGPCLoaderProps) => {
	const shouldHandleFileUpload = (): boolean => {
		switch (questionAttributeType) {
			case PhotoCaptureAttributeType.VIDEO_CAPTURE:
				if (uploadStatus === FileUploadStatus.IN_PROGRESS) return false;
				break;
			case PhotoCaptureAttributeType.DEFAULT:
				return isRAPFileUploadModalOpen ? false : isLoading;
		}
		return isLoading;
	};

	return shouldHandleFileUpload() ? (
		<StyledOverlayDiv>
			<StyledSpinnerDiv>
				<ProgressCircle />
			</StyledSpinnerDiv>
		</StyledOverlayDiv>
	) : null;
};
