import styled from '@emotion/styled';
import { Magnetic } from '@mitchell/react.component.ux';

type StyledDivShellContainerProps = {
	backgroundColor: string;
};

export const StyledDivShellContainer = styled.div`
	display: flex;
	flex-direction: row;
	min-height: 100%;
	max-width: 700px;
	margin: 0 auto;
	background-color: ${(props: StyledDivShellContainerProps) => props.backgroundColor};
`;

export const StyledDivContent = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: ${Magnetic.formControlCoreWidth};
`;
