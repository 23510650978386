import styled from '@emotion/styled';
import { Magnetic } from '@mitchell/react.component.ux';

export const StyledOverlayDiv = styled('div')`
	background-color: ${Magnetic.colorBlack};
	z-index: 99999;
	opacity: 0.7;
	position: fixed;
	height: 100%;
	width: 700px;
`;
export const StyledSpinnerDiv = styled('div')`
	position: fixed;
	background-color: ${Magnetic.colorWhite};
	z-index: 99999;
	opacity: 0.7;
	left: calc(50% - 30px);
	height: 70px;
	width: 70px;
	border-radius: 50%;
	top: calc(50% - 30px);
`;
