export const RouteConstant = {
	PBE_CONFIGURE_HOME: 'pbeConfigureHome',
	PBE_TERMS_AND_CONDITIONS: 'pbetermcondition',
	PBE_TERMINATE: 'pbeTerminate',
	PBE_IDENTIFICATION: 'pbeIdentification',
	PBE_ODOMETER: 'pbeOdometer',
	PBE_LICENSE_PLATE: 'pbeLicencePlate',
	PBE_GPC_MESSAGE: 'gpcMessage',
	PBE_WIDE_SHOT: 'pbeWideshot',
	PBE_CLOSEUP_SHOT: 'pbeCloseup',
	PBE_FOUR_CORNERS: 'pbecorners',
	PBE_REQUEST_ADDITIONAL: 'pbeadditionalattachmentquestion',
	PBE_REQUEST_OPTIONAL: 'pbeoptionalattachmentquestion',
	PBE_WALK_AROUND_VIDEO: 'pberecordvideo',
	PBE_YES_NO: 'pbeYesNo',
	PBE_SINGLE_CHOICE_SELECTION: 'pbeSingleChoiceSelect',
	PBE_MULTI_CHOICE_SELECTION: 'pbeMultiChoiceSelect',
	PBE_VIN_DECODE: 'pbeVinDecode',
};
